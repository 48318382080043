<script lang="ts" setup>
import type { OfferMedium } from '~/types/Catalog';

const props = defineProps<{
  offers: OfferMedium[];
  title?: string | null;
  recommendationsBlockId?: string;
}>();

const existingOffers = computed(() => {
  return props.offers.filter((offer) => offer.id);
});

const id = useId();

function scrollLeft() {
  const slider = document.getElementById(id);
  slider?.scrollBy({ left: -400, behavior: 'smooth' });
}

function scrollRight() {
  const slider = document.getElementById(id);
  slider?.scrollBy({ left: 400, behavior: 'smooth' });
}

const { sendOfferEventToDataLayer } = useGtmModule();

function handleProductClick(offer: OfferMedium, index: number) {
  sendOfferEventToDataLayer('select_item', offer, index);
  const route = useRoute();

  if (props.recommendationsBlockId) {
    route.query.recommended_by = 'dynamic';
    route.query.recommended_code = props.recommendationsBlockId;
  }

  navigateTo({
    path: offer.uri,
    query: route.query,
  });
}
</script>

<template>
  <section class="product-cards-gallery">
    <div
      v-if="title"
      class="px-3 lg:px-0"
      v-html="title"
    />

    <div class="product-cards-gallery__slider">
      <div
        :id
        class="product-cards-gallery__slider-wrapper"
      >
        <ProductCard
          v-for="(offer, key) in existingOffers"
          :key="offer.id"
          :offer
          :recommendations-block-id="recommendationsBlockId"
          class="product-cards-gallery__item"
          role="button"
          @click="handleProductClick(offer, key)"
        />
      </div>

      <div
        v-if="existingOffers.length > 4"
        class="swiper-navigation"
      >
        <button
          class="swiper-button swiper-button--prev"
          @click="scrollLeft"
        >
          <SvgoChevronLeft
            class="text-2xl"
            filled
          />
        </button>

        <button
          class="swiper-button swiper-button--next"
          @click="scrollRight"
        >
          <SvgoChevronRight
            class="text-2xl"
            filled
          />
        </button>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.product-cards-gallery {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include screen-lg {
    @include inner-content;
  }

  &__slider {
    position: relative;
    display: flex;
    overflow: hidden;

    &:hover {
      .swiper-navigation {
        opacity: 1;
      }
    }
  }

  &__slider-wrapper {
    display: flex;
    gap: 12px;
    overflow: auto;
    touch-action: pan-x pan-y;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__item {
    flex-shrink: 0;
    width: 100%;
    max-width: 46vw;

    &:first-of-type {
      margin-left: 12px;
    }

    &:last-of-type {
      margin-right: 12px;
    }

    @include screen-sm {
      width: 218px;
    }

    @include screen-lg {
      &:first-of-type {
        margin: 0;
      }

      &:last-of-type {
        margin: 0;
      }
    }
  }
}
</style>
